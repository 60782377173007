<template>
  <div class="Finish">
    <div class="main min-h-screen" :class="!success ? 'fail' : 'success'">
      <div class="flex-1 flex flex-col">
        <div class="logo justify-center flex items-center">
          <Logo />
        </div>
        <div style="padding-top: 5rem" class="w-full h-3/5 items-center justify-center text-center">
          <div v-if="!success" id="fail" class="content">
            <h1>{{ $t("finish.sad") }}</h1>
            <p>{{ $t("finish.correctAnswers", { numCorrect, total: correct.length }) }}</p>
            <div class="grid grid-cols-2 gap-4 py-10">
              <div>
                <h1>{{ numCorrect }}/9</h1>
                <p>{{ $t("finish.correct") }}</p>
              </div>
              <div>
                <h1>{{ time }}s</h1>
                <p>{{ $t("finish.time") }}</p>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-4 py-10">
              <div class="flex">
                <go to="/" class="button">
                  {{ $t("finish.retry") }}
                </go>
              </div>
              <div class="flex">
                <go to="/register" class="button-secondary ml-auto">
                  {{ $t("finish.register") }}
                </go>
              </div>
            </div>
          </div>
          <div v-else id="success" class="content">
            <h1>{{ $t("finish.gratulations") }}</h1>
            <p>{{ $t("finish.correctAnswers", { numCorrect, total: correct.length }) }}</p>
            <div class="grid grid-cols-2 gap-4 py-10">
              <div>
                <h1>{{ numCorrect }}/{{ correct.length }}</h1>
                <p>{{ $t("finish.correct") }}</p>
              </div>
              <div>
                <h1>{{ time }}s</h1>
                <p>{{ $t("finish.time") }}</p>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-4 py-10">
              <div class="flex">
                <go to="/" class="button">
                  {{ $t("finish.retry") }}
                </go>
              </div>
              <div class="flex">
                <go to="/register" class="button-secondary ml-auto">
                  {{ $t("finish.register") }}
                </go>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="success" class="answers-banner">
        <div class="py-5 content">
          {{ $t("finish.answers") }}
        </div>
      </div>
    </div>
    <div class="success">
      <div
        v-for="(selectedAnswers, answerIndex) in answers"
        :key="answerIndex"
        class="flex py-10 overflow-y-auto answers content"
      >
        <div class="number">
          <span id="qNumber" class="h1">{{ answerIndex + 1 }}.</span>
        </div>
        <div class="w-full">
          <h1 class="mb-12">
            {{ $tm(`questions[${answerIndex}].question`) }}
          </h1>
          <p id="answerText" class="block mb-1">
            Deine Antwort:
          </p>
          <div class="answers mb-3">
            <div
              v-for="(answer, i) in selectedAnswers"
              :key="i"
              :class="['answer', isCorrect(answerIndex, answer) ? 'correct' : 'wrong']"
            >
              <p class="list-item font-bold">
                {{ $tm(`questions[${answerIndex}].answers[${answer}]`) }}
              </p>
              <close class="wrong-icon" />
              <correct-icon class="correct-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Logo from '@/assets/images/UPD-Logo-White.svg';
import Close from '@/assets/images/icons/close.svg';
import Correct from '@/components/utils/Correct.vue';

export default {
  components: {
    Logo,
    Close,
    CorrectIcon: Correct,
  },
  props: { index: Number },
  setup() {
    const store = useStore();
    const correct = computed(() => Object.values(store.state.correct));
    const answers = computed(() => Object.values(store.state.answers).map(
      (answer) => (Array.isArray(answer) ? answer : [answer]),
    ));
    const time = computed(() => store.state.seconds);

    const numCorrect = computed(() => correct.value.reduce((corr, current) => (current ? corr + 1 : corr), 0));
    // const numCorrect = computed(() => 3);

    const success = computed(() => numCorrect.value >= 5);

    const { tm } = useI18n();

    const isCorrect = (questionIndex, answer) => {
      const correctAnswer = tm(`questions[${questionIndex}].correct`);
      return Array.isArray(correctAnswer)
        ? correctAnswer.includes(answer)
        : correctAnswer === answer;
    };

    return {
      correct,
      answers,
      numCorrect,
      success,
      time,
      isCorrect,
    };
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 4rem;
}
.button,
.button-secondary {
  font-weight: 700;
  padding: 13px 3rem;
  width: 90%;
}
.content {
  max-width: 900px;
  margin: 0 auto;
}
#fail *,
#success * {
  color: #fff;
}
.Finish .main.fail > :first-child {
  background-color: var(--fail-bg);
}
.Finish .main.success > :first-child {
  background-color: var(--success-bg);
}
.Finish .main.success {
  h1 {
    font-size: 2.5rem;
  }
}

.success .content {
  max-width: 700px;
  position: relative;
  overflow: visible;

  .number {
    @apply pt-10;
    position: absolute;
    top: 0;
    left: -5rem;
    height: 5rem;

    .h1 {
      font-size: 3rem;
    }
  }
}
.logo {
  max-width: 100px;
  width: 100%;
  margin: 2rem auto 0px auto;
}
.answers-banner {
  background-color: var(--gray-with-alpha);
  color: var(--gray);
  width: 100%;
}

#answerText {
  color: var(--gray);
}
.answers {
  h1, .h1 {
    color: var(--gray);
    font-size: 1.5rem;
  }
}
.sad-retry {
  width: auto;
}
</style>
